<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__preproject">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="项目编码" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="export" v-if="$hasPermission('opm:preproject:export')" @click="exportHandle()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:preproject:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:preproject:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border
                @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle"
                style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="code" label="项目编码" width="200" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="name" label="项目名称" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="planCost" label="计划总投资（万元）" width="200" align="right" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="planStart" label="计划开工日期" width="120" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="planEnd" label="计划完工日期" width="120" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="workflow" label="配置" width="150" align="center" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button v-if="$hasPermission('opm:preproject:workflow')" size="mini" icon="el-icon-s-tools" @click="workFlowSetHandle(row.id)">前期工作配置</el-button>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="210">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:preproject:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:preproject:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:preproject:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 前期项目工作流设置 -->
      <preproject-workflow-setting v-if="workFlowSetVisible" ref="workFlowSet" @close="closeWorkFlowSetDialogHandle"></preproject-workflow-setting>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './preproject-add-or-update'
import ViewPage from './preproject-view'
import PreprojectWorkflowSetting from './preproject_workflow_setting.vue'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/opm/preProject/page',
        getDataListIsPage: true,
        exportURL: '/opm/preProject/export',
        deleteURL: '/opm/preProject',
      },
      dataForm: {
        name: '',
        code: ''
      },
      workFlowSetVisible: false
    }
  },
  components: {
    AddOrUpdate,
    ViewPage,
    PreprojectWorkflowSetting
  },
  methods: {
    workFlowSetHandle(prjId) {
      this.workFlowSetVisible = true
      this.$nextTick(() => {
        this.$refs.workFlowSet.dataForm.prjId = prjId
        this.$refs.workFlowSet.init()
      })
    },
    closeWorkFlowSetDialogHandle() {
      this.workFlowSetVisible = false
    }
  }
}
</script>