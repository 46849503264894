<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__archivemeta">
      <my-container :leftWidth="350">
        <template v-slot:left>
          <el-button type="text" size="mini" icon="el-icon-plus" @click="addData()" v-if="canEdit()" :loading="dataListLoading">新增分类</el-button>
          <div class="tree-container">
            <vxe-table class="sortable-row-gen" height="auto" ref="xTable" show-overflow
                       :loading="dataListLoading" :row-config="rowConfig" :data="dataList" :edit-config="editConfig"
                       @cell-click="cellClick" :tooltip-config="tooltipConfig" @cell-mouseenter="mouseEnter" @cell-mouseleave="mouseLeave">
              <vxe-column width="40" :visible="canEdit()" :title-prefix="{message: '按住后可以上下拖动排序', icon: 'vxe-icon-question-circle-fill'}">
                <template #default>
                   <span class="drag-btn">
                     <i class="vxe-icon--menu"></i>
                   </span>
                </template>
              </vxe-column>
              <vxe-column field="name" title="元数据分类" :edit-render="{name: '$input', events: {change: nameChange}}">
                <template #default="{ row }">
                  <span>{{ row.name }}</span>
                  <span v-show="row.show" style="margin-left: 20px">
                    <el-button type="text" style="color: blue" icon="el-icon-edit-outline" size="mini" @click.stop="() => updateEvent(row)" v-if="canEdit()"></el-button>
                    <el-button type="text" style="color: red" icon="el-icon-delete" size="mini" @click.stop="() => deleteEvent(row)" v-if="$hasPermission('mps:archivemetatype:delete')"></el-button>
                  </span>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
        <template v-slot:right>
          <!-- 元数据列表 -->
          <archivemeta-list ref="archivemeta" :table-height="tableHeight"></archivemeta-list>
        </template>
      </my-container>
    </div>
  </el-card>
</template>
<script>
import mixinViewModule from '@/mixins/view-module'
import ArchivemetaList from './archivemeta-list'
import Sortable from 'sortablejs'

export default {
  mixins: [mixinViewModule],
  components: {ArchivemetaList},
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/archiveMetaType/list',
        deleteURL: '/mps/archiveMetaType',
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      tableHeight: 400,
      rowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        keyField: 'id'
      },
      editConfig: {
        enabled: this.canEdit(),
        trigger: 'manual',
        mode: 'cell'
      },
      tooltipConfig: {}, // 表格提示配置。当使用cell-mouseenter、cell-mouseleave事件时，需要显示配置tooltipConfig，否则控制台有警告
      currentSelectRow: null,
    }
  },
  created() {
    this.tableHeight = window.innerHeight - 200
    this.getDataList()
    this.$nextTick(() => {
      this.rowDrop()
      this.refreshMetaDataList()
    })
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  },
  methods: {
    // 列表拖动
    rowDrop() {
      this.$nextTick(() => {
        const $table = this.$refs.xTable
        this.sortable = Sortable.create($table.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({newIndex, oldIndex}) => {
            if (newIndex != oldIndex) {
              let {fullData} = $table.getTableData()
              const newSort = fullData[newIndex].sortNo
              if (newIndex < oldIndex) {
                // 前移
                for (let i = newIndex; i < oldIndex; i++) {
                  fullData[i].sortNo = fullData[i].sortNo + 1
                }
              } else {
                // 后移
                for (let i = newIndex; i > oldIndex; i--) {
                  fullData[i].sortNo = fullData[i].sortNo - 1
                }
              }

              // 移动元素插入到要移动的位置
              const currRow = fullData.splice(oldIndex, 1)[0]
              currRow.sortNo = newSort
              fullData.splice(newIndex, 0, currRow)
              // 更新数据
              this.$http.put('/mps/archiveMetaType/moveSort', fullData[newIndex]).then(({data: res}) => {
                if (res.code !== 0) {
                  return this.$message.error(res.msg)
                }
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
              }).catch(() => {
                return this.$message.error("操作出错")
              })
            }
          }
        })
      })
    },
    canEdit() {
      return this.$hasPermission('mps:archivemetatype:edit')
    },
    async addData() {
      this.dataListLoading = true
      const $table = this.$refs.xTable
      const record = {
        sortNo: 0,
        name: '新增分类'
      }
      try {
        // 保存数据
        const {data: saveRes} = await this.$http['post']('/mps/archiveMetaType/', record)
        if (saveRes.code !== 0) {
          this.dataListLoading = false
          return this.$message.error(saveRes.msg)
        }
        record.id = saveRes.data.id
        record.sortNo = saveRes.data.sortNo
        // 最末级插入
        const {row: newRow} = await $table.insertAt(record, -1)
        await $table.setEditCell(newRow, 'name')
        this.currentSelectRow = null
        this.dataListLoading = false
      } catch (error) {
        this.dataListLoading = false
        return this.$message.error("出错了")
      }
    },

    mouseEnter({row}) {
      this.$set(row, 'show', true)
    },

    mouseLeave({row}) {
      this.$set(row, 'show', false)
    },

    // 左侧元数据分类的点击事件
    cellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        // 点击相同分类两次，右侧查询全部元数据列表
        if (this.currentSelectRow && this.currentSelectRow.id == row.id) {
          this.currentSelectRow = null
        } else {
          this.currentSelectRow = row
        }
        this.refreshMetaDataList()
      }
    },

    updateEvent(row) {
      // 激活单元格编辑
      this.$refs.xTable.setEditCell(row, 'name')
    },
    deleteSuccessCallback({row}) {
      this.$refs.xTable.remove(row)
      this.currentSelectRow = null
      this.refreshMetaDataList()
    },
    deleteEvent(row) {
      if (row) {
        this.deleteHandle(row.id, {deleteSuccessCallbackArgs: {row}, autoQuery: false})
      }
    },
    nameChange({row}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        if (event.value == '') {
          this.$message.error("分类名称不能为空！")
          let previousName = row.name
          setTimeout(() => {
            row.name = previousName
            this.updateEvent(row)
          }, 100)
        } else {
          let updateData = {
            id: row.id,
            name: event.value
          }
          // 更新元数据分类
          this.$http['put']('/mps/archiveMetaType/', updateData).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
          }).catch(() => {
            this.$message.error('出错了')
          })
        }
      }
    },
    // 刷新元数据列表
    refreshMetaDataList() {
      this.$refs.archivemeta.dataForm.typeId = this.currentSelectRow && this.currentSelectRow.id != 0 ? this.currentSelectRow.id : ''
      this.$refs.archivemeta.initData()
    }
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}

.sortable-row-gen .drag-btn {
  cursor: move;
  font-size: 12px;
}

.sortable-row-gen .vxe-body--row.sortable-ghost,
.sortable-row-gen .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
</style>