<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="320">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader ref="contractCascader" include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
          <el-form-item>
            <el-input v-model="treeFilterText" placeholder="输入关键字查询" ></el-input>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
            v-loading="treeLoading"
            :data="treeData"
            node-key="id"
            :props="defaultProps"
            ref="tree"
            @node-click="treeNodeClick"
            :highlight-current="true"
            :filter-node-method="filterNode">
          </el-tree>
        </div>
      </template>
	    <template v-slot:right>
        <div class="mod-__subboq">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <template v-if="dataForm.subcontractorId != '' || dataForm.subcontractorId == undefined">
              <el-form-item>
                <el-input v-model="dataForm.subItemName" placeholder="部位名称" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button-group style="margin-top: -3px;">
                  <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                  <toolbar-button role="add" v-if="(locked != 1) && $hasPermission('mps:subboq:save')" @click="batchAdd()"></toolbar-button>
                  <toolbar-button role="delete" v-if="(locked != 1) && $hasPermission('mps:subboq:delete')" @click="deleteHandle()"></toolbar-button>
                  <toolbar-button role="import" v-if="(locked != 1) && $hasPermission('mps:subboq:import')" @click="importHandle"></toolbar-button>
                  <toolbar-button role="export" v-if="$hasPermission('mps:subboq:export')" @click="exportHandle"></toolbar-button>
                  <toolbar-button role="lock" v-if="locked != 1" @click="lock()" :disabled="!$hasPermission('mps:subboq:lock')"></toolbar-button>
                  <toolbar-button role="unlock" v-else @click="unlock()" :disabled="!$hasPermission('mps:subboq:lock')"></toolbar-button>
                </el-button-group>
              </el-form-item>
            </template>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column prop="boqCode" label="清单编号" width="160" header-align="center" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="boqName" label="清单名称" min-width="160" header-align="center" align="left" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.boqName }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="subItemCode" label="部位编号" width="220" header-align="center" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subItemName" label="部位名称" min-width="260" header-align="center" align="left" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.subItemName }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量" width="150" header-align="center" align="right" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-input v-if="(locked != 1) && $hasPermission('mps:meas:update')" v-model="row.num" @change="numEdit(row)"></el-input>
                <span v-else>{{row.num}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('handle')" header-align="center" align="center" width="140">
              <template v-slot="{row}">
                <table-button role="edit" v-if="$hasPermission('mps:subboq:update') && (locked != 1)" @click="addOrUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('mps:subboq:delete') && (locked != 1)" @click="deleteHandle(row.id)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:subboq:update') || (locked == 1), row.boqName)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
          <!-- 弹窗，批量新增 -->
          <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeDialogHandle"></batch-add>
          <!-- excel文件导入弹窗 -->
          <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList()" url="/mps/subBoq/import"></excel-import>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './subboq-add-or-update'
import BatchAdd from './subboq-batch-add'
import ViewPage from './subboq-view'
import {isNumber} from "@/utils/validate";

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/subBoq/page',
        getDataListIsPage: true,
        exportURL: '/mps/subBoq/export',
        deleteURL: '/mps/subBoq',
        showEmptyDataWarningMessage: true
      },
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: '',
        subItemName: '',
        boqId: '',
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      treeFilterText: '',
      treeLoading: false,
      treeNodeIsLeaf: false,
      batchAddVisible: false,
      hasSubcontractor: true,
      locked: 0,
    }
  },
  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    },
  },
  components: {
    AddOrUpdate,
    BatchAdd,
    ViewPage,
  },
  methods: {
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId

      this.locked = data.contract.lockSubBoq === null ? 0 : data.contract.lockSubBoq
      if (data.subcontractor) {
        this.locked = data.subcontractor.lockSubBoq === null ? 0 : data.subcontractor.lockSubBoq
      }
      this.hasSubcontractor = data.contract.hasSubcontractor
      this.getTreeData()
      this.getDataList()
    },
    getTreeData() {
      this.treeLoading = true
      this.$http.get(
          '/mps/subBoq/boqTreeList',
          {
            params: {
              contractId: this.dataForm.contractId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return
        }
        this.treeData = res.data
        this.treeNodeIsLeaf = false
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    treeNodeClick(data, node, ele) {
      // 根据点击的节点是否为叶节点，修改本地变量，用于判断是否能新增
      // 点击高亮节点则取消高亮，并查全部清单的数据
      if (this.dataForm.boqId == data.id) {
        this.treeNodeIsLeaf = false
        this.dataForm.boqId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.treeNodeIsLeaf = node.isLeaf
        this.dataForm.boqId = data.id
      }
      this.getDataList()
    },
    // 批量新增
    batchAdd() {
      if (this.treeNodeIsLeaf) {
        this.batchAddVisible = true
        this.$nextTick(() => {
          this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
          this.$refs.batchAdd.dataForm.subcontractorId = this.dataForm.subcontractorId
          this.$refs.batchAdd.dataForm.boqId = this.dataForm.boqId
          this.$refs.batchAdd.init()
        })
      } else {
        this.$message({
          message: '请先选择一个清单末级节点',
          type: 'warning',
          duration: 1500,
        })
      }
    },
    lock() {
      this.$confirm(`您确定锁定0#清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.hasSubcontractor) {
          url = `/mps/subcontractor/lockSubBoq/${this.dataForm.subcontractorId}`
        } else {
          url = `/mps/contract/lockSubBoq/${this.dataForm.contractId}`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 1
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 1)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 1)
          }
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    unlock() {
      this.$confirm(`您确定要解锁0#清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.hasSubcontractor) {
          url = `/mps/subcontractor/unlockSubBoq/${this.dataForm.subcontractorId}`
        } else {
          url = `/mps/contract/unlockSubBoq/${this.dataForm.contractId}`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 0)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 0)
          }
          this.locked = 0
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.addOrUpdateVisible = false
      this.viewVisible = false
      this.batchAddVisible = false
    },
    // 修改数量
    numEdit(row) {
      if (!isNumber(row.num)) {
        this.$message({
          message: '数量必须为数字！',
          type: 'warning',
          duration: 3000,
        })
        row.num = null
      } else {
        this.$http['put']('/mps/subBoq/', row).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
          })
        }).catch()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 220px);
  }
</style>