<template>
  <div class="mod-__archivemeta">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.keyword" placeholder="输入关键字查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button-group style="margin-top: -3px;">
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          <toolbar-button role="add" v-if="$hasPermission('mps:archivemeta:save')" @click="addOrUpdateHandle()"></toolbar-button>
          <toolbar-button role="delete" v-if="$hasPermission('mps:archivemeta:delete')" @click="deleteHandle()"></toolbar-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table v-loading="dataListLoading" :max-height="tableHeight"
              :data="dataList" highlight-current-row border
              @sort-change="dataListSortChangeHandle"
              @selection-change="dataListSelectionChangeHandle"
              style="width: 100%;" :cell-style="{padding: '2px 0'}">
      <el-table-column type="selection" align="center" width="50"></el-table-column>
      <el-table-column prop="name" label="元数据名称" sortable="custom" min-width="300" show-overflow-tooltip></el-table-column>
      <el-table-column prop="typeName" label="元数据分类" sortable="custom" show-overflow-tooltip width="280"></el-table-column>
      <el-table-column prop="code" label="元数据编号" sortable="custom" align="center" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dataType" label="数据类型" width="120" align="center" sortable="custom" show-overflow-tooltip>
        <template v-slot="{row}">
          <span v-if="row.dataType === 1">文本</span>
          <span v-else-if="row.dataType === 2" style="color: #2278b2">日期</span>
          <span v-else-if="row.dataType === 3" style="color: #8ea706">数值</span>
          <span v-else style="color: #ee0a30">未知</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" align="center" width="140">
        <template v-slot="{row}">
          <table-button role="update" v-if="$hasPermission('mps:archivemeta:update')" @click="addOrUpdateHandle(row.id)"></table-button>
          <table-button role="delete" v-if="$hasPermission('mps:archivemeta:delete')" @click="deleteHandle(row.id)"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
  </div>
</template>
<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from "./archivemeta-add-or-update";

export default {
  mixins: [mixinViewModule],
  components: {
    AddOrUpdate
  },
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/archiveMeta/page',
        getDataListIsPage: true,
        deleteURL: '/mps/archiveMeta',
      },
      dataForm: {
        typeId: '', // 元数据分类ID
        keyword: '' // 按编号或名称检索
      },
    }
  },
  props: {
    tableHeight: {
      type: Number,
      required: true,
      default: 400
    },
  },
  methods: {
    initData() {
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.typeId = this.dataForm.typeId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>

<style scoped>

</style>